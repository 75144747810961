.body {
  width: 750px;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-top: 20px;
}

.DNIButton {
  width: 120px;
  margin-bottom: 15px;
  margin-left: 10px;
}

.editButton{
  margin-right:10px;
}

.actaInput {
  width: 7%;
}

.side-by-side {
  display: flex;
  flex-direction: row;
  padding:20px;
}

.labelInput {
  margin-left: 40px
}

.gsfsafsFSFSf {
  margin-left: 20px;
}

.gsdgjasf {
  margin-left: 45%;
  padding: 10px
}


.label {
  margin-top: -2.9%;
  width: 100px; 
  margin-left: 120px;
  margin-bottom: 20px;
}

.dropdown {
  margin-top:-3.5%;
  width: 100px;
  margin-left:120px;
  margin-bottom: 20px;
}

.dropdownDictado {
  margin-top: -3.5%;
  width: 250px;
  margin-left: 200px;
  margin-bottom: 20px;
}

.card {
  text-align: center;
  padding: 10px;
  width: 300px;
  margin-top:15px;
  padding: 1em;
}

.div {
  margin-bottom: 15px;
  margin-top: 20px;
}

.editButtonDelete {
  margin-right: 10px;
  margin-left : 7%
}

.labelError {
  color: #f44336;
}

.labelMessage {
  color: #33A1FF;
}