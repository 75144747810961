.main-nav li .glyphicon {
  margin-right: 10px;
}

/* Highlighting rules for nav menu items */
.main-nav li a.active,
.main-nav li a.active:hover,
.main-nav li a.active:focus {
  background-color: #4189C7;
  color: white;
}

/* Keep the nav menu independent of scrolling and on top of other items */
.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.text-primary.my-auto.nav-item.dropdown {
  margin-top: 0%;
  width: 0px;
  margin-right: 10px;
  margin-bottom: 0px
}

.dropdown-item {
  width: 100% !important
}

